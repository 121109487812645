import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroTwo'
import NewsSection from '../components/homeNews'
import ColumnOverlayLogos from '../components/columnOverlayLogos'
import ColumnAlternating from '../components/columnAlternatingStyled'
import RecommendedContent from "../components/recommendedContent"
import FeatureCTA from '../components/ctaFeature'

export default ({ data, location }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <div id="intro-page">
        <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + post.frontmatter.foreimage.childImageSharp.fluid.src}          
        />

        <Hero
          class="home-110"
          foreimage={post.frontmatter.foreimage.childImageSharp.fluid}
          forealt={post.frontmatter.forealttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctaslug="#scrollto"
          ctatext="Why UNICOM Engineering?"
          ctaslug2="https://go.unicomengineering.com/Contact-Us?pg-name=home"
          ctatext2="Schedule a consultation"
          type="home"
        />
      </div>

      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="#FFFFFF" ></path>
          </svg>
        </div>
      </div>

      <main id="main">
        <MDBContainer className="pb-100">
          <ColumnOverlayLogos logobox={post.frontmatter.logobox} />
        </MDBContainer>

        <MDBAnimation reveal type="fadeIn" duration="1587ms">
          <FeatureCTA
            ctafeaturetitle={post.frontmatter.cta.title}
            ctafeaturesubtitle={post.frontmatter.cta.subtitle}
            ctafeaturelink={post.frontmatter.cta.link}
            ctafeaturelinktext={post.frontmatter.cta.linktext}
            imageUrl={post.frontmatter.cta.image.childImageSharp.fixed}
            imageAltText={post.frontmatter.cta.alttext}
            bgimageUrl={post.frontmatter.cta.bgimage.childImageSharp.fixed}
            bgimageAltText={post.frontmatter.cta.bgalttext}
          />
        </MDBAnimation>

        {post.frontmatter.section ? (
          <section className="bg-white-grey-gradient">
            <MDBAnimation type="fadeIn" duration="1587ms">
              <h2 className="font-alt font-w-700 title-xs-medium title-extra-large letter-spacing-1 text-center mt-4 mt-xl-0 mb-7"
                dangerouslySetInnerHTML={{ __html: post.fields.frontmattermd.spotlighttitle.html, }} />              
              <MDBContainer>
                <div className="about-overview">
                  {post.frontmatter.section.map((sections, index) => {
                    return (
                      <ColumnAlternating
                        key={index}
                        title={sections.title}
                        subtitle={sections.subtitle}
                        subtitlecolour={ sections.subtitlecolour != 'none' ? sections.subtitlecolour : 'text-very-dark-blue' }
                        description={sections.description}
                        imageUrl={sections.image.childImageSharp.fluid}
                        imageAltText={sections.alttext}
                        placement={sections.placement}
                        titleClass="font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium text-very-dark-blue"
                        link={sections.btnlink == 'capabilities' ? '/solutions/' + sections.btnlink : '/' + sections.btnlink }
                        colour={sections.btncolour}
                      />
                    )
                  })}
                </div>
              </MDBContainer>
            </MDBAnimation>
          </section>
        ) : (
          ''
        )}

        <RecommendedContent fallback="whats-new" />
        
        <NewsSection />

      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }    
    markdownRemark(frontmatter: { name: { eq: "index" } }) {
      id
      html
      fields {
        frontmattermd {
          spotlighttitle {
            html
          }
        }
      }
      frontmatter {
        meta {
          title
          description 
        }
        title
        subtitle
        bodytitle
        logobox {
          logoboxtitle
          logoboxsubtitle
          logo {
            logoboximage {
              childImageSharp {
                fluid(maxWidth: 710, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            logoboxalttext
            logoboxlink
          }
          logobutton {
            logoboxbtntxt
            logoboxbtnlink
          }
        }
        foreimage {
          childImageSharp {
            fluid(maxWidth: 540, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        forealttext
        section {
          title
          subtitle
          subtitlecolour
          description
          image {
            childImageSharp {
              fluid(maxWidth: 960, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          placement
          alttext
          btnlink
          btncolour
        }
        cta {
          title
          subtitle
          link
          linktext
          image {
            childImageSharp {
              fixed(width:80, height: 98, quality: 90) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
          alttext
          bgalttext
          bgimage {
            childImageSharp {
              fixed(width: 396, height: 443, quality: 90) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }      
        }
      }
    }
  }
`
